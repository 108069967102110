import "./App.css";
import RouteUrls from "./components/RouteUrls";
import React, { useEffect, useState } from "react";
import PopupWindow from "./components/fixed components/PopupWindow";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Load Tawk.to script
  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawkScript = document.createElement("script");
    tawkScript.async = true;
    tawkScript.src = "https://embed.tawk.to/670fab842480f5b4f58e3fa0/1iaajc0dt";
    tawkScript.charset = "UTF-8";

    tawkScript.onerror = () => {
      console.error("Failed to load the Tawk.to script.");
    };

    document.body.appendChild(tawkScript);

    return () => {
      document.body.removeChild(tawkScript);
    };
  }, []);

  // Open modal on initial visit
  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <div className="App">
      <main>
        {/* Wrap everything in RouteUrls to ensure PopupWindow is inside the BrowserRouter */}
        <RouteUrls>
          <PopupWindow isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </RouteUrls>
      </main>
    </div>
  );
}

export default App;
